'use client';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { ToastOptions } from 'react-toastify/dist/types';

const contextClass = (options: ToastOptions) => {
  switch (options.type) {
    case 'success':
      return 'bg-indigo-intense';
    case 'error':
      return 'bg-red-700';
    case 'info':
      return 'bg-indigo-intense';
    default:
      return 'bg-indigo-intense';
  }
};

const toastClassName = (options: any) => `
  flex items-center min-w-[429px] mx-auto ${contextClass(options)} 
  min-h-0 rounded-[2px] p-4
`;

export const Toaster = () => (
  <ToastContainer
    position="bottom-left"
    toastClassName={toastClassName}
    hideProgressBar
    icon={false}
    closeButton={() => <span />}
    bodyClassName="font-inter text-white font-medium text-xs"
    autoClose={3000}
    rtl={false}
    draggable
  />
);
