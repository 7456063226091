import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';

import { injectGlobalCss } from '@/styles/global';
import '@/styles/globals.css';

import { ClarityScript } from '@/components/ClarityScript/ClarityScript';
import { FacebookPixel } from '@/components/FacebookPixel/FacebookPixel';
import { Toaster } from '@/components/Toaster/Toaster';
import { RouterWrapper } from '@/landing/components/RouterWrapper/RouterWrapper';
import { GoogleAnalytics } from 'nextjs-google-analytics';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/900.css';

import '@fontsource/sora/600.css';
import '@fontsource/sora/700.css';

import '@fontsource/space-grotesk/400.css';
import '@fontsource/space-grotesk/500.css';
import '@fontsource/space-grotesk/600.css';
import '@fontsource/space-grotesk/700.css';
//
// core styles shared by all of react-notion-x (required)
import 'react-notion-x/src/styles.css';

injectGlobalCss();

interface AppPropsWithToken extends AppProps {}

function MyApp({ Component, pageProps }: AppPropsWithToken) {
  return (
    <RouterWrapper>
      <Toaster />
      <GoogleAnalytics trackPageViews />
      <ClarityScript />
      <FacebookPixel />
      <Component {...pageProps} />
    </RouterWrapper>
  );
}

export default appWithTranslation(MyApp);
