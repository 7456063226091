import { injectGlobal } from '@emotion/css';

export const injectGlobalCss = () =>
  injectGlobal`

    @tailwind base;
    @tailwind components;
    @tailwind utilities;
    
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    
    
    html {
      scroll-behavior: smooth !important;
    }

    :root {
      --inter-font: 'Inter', sans-serif;
      --sora-font: 'Sora', sans-serif;
      --space-grotesk-font: 'Space Grotesk', sans-serif;
    }

    body {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
    }
    
    ul, ol {
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    
    h1, h2, h3, h4, h5, h6 {
      font-family: 'Sora', sans-serif;
    }

    // Editor.js styles

    .ce-paragraph a {
      word-break: break-all;
    }
    
    .codex-editor {
      z-index: 0;
    }
    
    .image-tool__caption, .embed-tool__caption {
      display: none;
    }
    
    .ce-block__content {
      width: 100%;
      margin: unset;
      max-width: unset;
    }
    
    .video-tool__video > div:first-of-type {
      width: 100% !important;
      height: auto !important;
    }

    .ce-toolbar, .ce-toolbar--opened {
      z-index: 9;
    }

    .codex-editor--narrow, .codex-editor__redactor {
      // Hack: global document editor padding reset
      padding: 0 !important;
      margin: 0 !important;
    }

    .upload-wrapper {
      /* gray/50 */

      background: #fafafa;
      /* gray/200 */

      border: 1px solid #e4e4e7;
      padding: 16px 24px;
      margin-bottom: 4px;
    }

    .upload-desc {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      /* or 14px */

      /* gray/600 */
      margin: 8px 0 0 0;

      color: #52525b;
    }

    .hidden {
      display: none;
    }

    .selected-file {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;
    }

    .selected-file-wrapper {
      /* gray/50 */

      background: #fafafa;
      /* gray/200 */
      padding: 8px 16px;
      border: 1px solid #e4e4e7;
      border-radius: 2px;
      margin-bottom: 4px;
    }

    .selected-file-wrapper svg {
      cursor: pointer;
    }

    .selected-file-name {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 132%;
      color: #222225;
      margin: 0;
      padding-right: 8px;
      text-decoration: none !important;
    }

    .selected-file-name:hover {
      text-decoration: underline !important;
    }

    .upload-btn {
      display: inline-block;
      padding: 8px;
      cursor: pointer;
      /* gray/50 */

      background: #FAFAFA;
      /* gray/200 */

      border: 1px solid #E4E4E7;
      border-radius: 2px;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 4px;
      color: #222225;
    }

    .upload-btn:hover {
      border: 1px solid #222225;
    }

    .uploading-btn {
      opacity: 0.7;
      cursor: not-allowed;
    }
    
    .uploading-btn:hover {
      border: 1px solid #E4E4E7;
    }
    
    @media (max-width: 768px) {
      .notion-page {
        width: auto;
        padding-left: 0;
        padding-right: 0;
      }
    }

  `;
